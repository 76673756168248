import { Component, Inject, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { interval, Observable, Subscription } from 'rxjs';

import { selectRouter, getProfile } from './+state/root.selectors';
import { DOCUMENT } from '@angular/common';
import { environment } from '@tuacar-workspace/core';
import { loadProfile, logout } from './+state/root.actions';
import { SwUpdate } from '@angular/service-worker';
import { UserModel } from './models/profile.models';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'tuacar-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'TuaCar Manager';
  version: string;
  isCollapsed = false;
  loginDisplay = false;
  profile$: Observable<UserModel | undefined>;
  router$ = this.store.pipe(select(selectRouter));
  profilePhoto = '';
  sub: Subscription = new Subscription();

  constructor(
    private store: Store,
    @Inject(DOCUMENT) public document: Document,
    private swUpdate: SwUpdate,
    private modal: NzModalService
  ) {
    this.version = environment.version;

    // this.auth.getAccessTokenSilently().subscribe((token) => {
    //   console.log(token);
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   const tokenInfo = jwt_decode(token) as any; // decode token
    //   //const expireDate = tokenInfo.exp; // get token expiration dateTime
    //   console.log(tokenInfo.permissions); // show decoded token object in console
    // });

    this.profile$ = this.store.pipe(select(getProfile));

    const updatesAvailable = this.swUpdate.versionUpdates;

    if (this.swUpdate.isEnabled) {
      // Required to enable updates on Windows and ios.
      this.swUpdate.activateUpdate().then();

      interval(30000).subscribe(() => {
        this.swUpdate.checkForUpdate().then(() => {
          // console.log('checking for updates');
        });
      });
    }

    this.sub.add(
      updatesAvailable.subscribe(async (evt) => {
        //console.log('UpdateService: versionUpdates', evt);
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${evt.version.hash}`);
            break;
          case 'VERSION_READY':
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(
              `New app version ready for use: ${evt.latestVersion.hash}`
            );
            await this.swUpdate.activateUpdate();
            this.modal.confirm({
              nzTitle: 'Nuova versione disponibile',
              nzContent: `Caricare l'ultima versione?`,
              nzOnOk: () =>
                new Promise(() => {
                  window.location.reload();
                }).catch(() => console.log('Oops errors!')),
            });
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(
              `Failed to install app version '${evt.version.hash}': ${evt.error}`
            );
            break;
        }
      })
    );
  }
  ngOnInit(): void {
    this.store.dispatch(loadProfile());
  }

  logout() {
    this.store.dispatch(logout());
  }
}
