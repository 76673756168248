import { gql } from 'apollo-angular';

export const GET_PROFILE = gql`
  query me {
    me {
      roles
      agenzie {
        code
        description
        indirizzo
        cap
        citta
        provincia
        ruolo
      }
    }
  }
`;
