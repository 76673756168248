/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { UserModel } from '../models/profile.models';

export const initRoot = createAction('[Root Page] Init');

export const loadProfile = createAction('[Root/API] Load Profile');

export const loadProfileSuccess = createAction(
  '[Root/API] Load Profile Success',
  props<{ user: UserModel }>()
);

export const loadProfileFailure = createAction(
  '[Root/API] Load Profile Failure',
  props<{ error: any }>()
);

export const logout = createAction('[Root/API] Logout');

export const go = createAction('[ROUTER] go', props<{ path: string }>());

export const back = createAction('[ROUTER] back');
export const forward = createAction('[ROUTER] forward');
