import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '@tuacar-workspace/core';
import { HttpHeaders } from '@angular/common/http';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/angular';

export function createApollo(httpLink: HttpLink) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const errorHandlerLink = onError((err, ...param) => {
    const { graphQLErrors, networkError } = err;
    if (graphQLErrors)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      graphQLErrors.map(({ message, locations, path }) => {
        Sentry.captureMessage(message);
      });
    if (networkError) {
      Sentry.captureException(networkError);
    }
  });

  const uri = environment.graphqlUri;
  return {
    link: errorHandlerLink.concat(
      httpLink.create({
        uri,
        headers: new HttpHeaders({
          'GraphQL-Preflight': 1,
        }),
      }),
    ),
    cache: new InMemoryCache({
      addTypename: true,
    }),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
