import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client';
import { AgenziaRefModel } from '../models/core.model';

@Injectable({
  providedIn: 'root',
})
export class AgenziaService {
  constructor(private apollo: Apollo) {}

  loadAgenziaRefList(): Observable<AgenziaRefModel[]> {
    return this.apollo
      .watchQuery({
        query: GET_AGENZIA_REF_QUERY,
        returnPartialData: false,
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((result: ApolloQueryResult<any>) => {
          const resp = result.data.agenzie as AgenziaRefModel[];

          return resp;
        }),
      );
  }
}

export const GET_AGENZIA_REF_QUERY = gql`
  query agenzie {
    agenzie(onlyEnabled: true) {
      id
      code
      description
      isFatturazione
      isPrincipale
      isFranchisor
    }
  }
`;
