import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import { UserModel } from '../models/profile.models';

import * as RootActions from './root.actions';

export const ROOT_FEATURE_KEY = 'root';

export interface RootState {
  user?: UserModel;
  error?: string | null; // last known error (if any)
}

export const INITIAL_STATE: RootState = {};

export const rootReducer = createReducer(
  INITIAL_STATE,
  on(RootActions.initRoot, (state) => ({
    ...state,
    error: null,
  })),
  on(RootActions.loadProfileSuccess, (state, action) => {
    return {
      ...state,
      user: action.user,
    };
  }),
  on(RootActions.loadProfileFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

// router
export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
};

export interface AppState {
  router?: RouterReducerState;
}
