import { AppState } from '..';
import { createSelector } from '@ngrx/store';

export const selectRouter = createSelector(
  (state: AppState) => state.router,
  (value) => value
);

// export const selectRouter = createSelector<
//   AppState,
//   fromRouter.RouterReducerState<any>
// >('router');
