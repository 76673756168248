import { Route } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { HomeComponent } from './home/home.component';
import { UserComponent } from './user/user.component';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    component: HomeComponent,
  },
  {
    path: 'load-car',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@tuacar-workspace/external-load').then(
        (m) => m.ExternalLoadModule
      ),
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    component: UserComponent,
  },
];
