import { createReducer, on } from '@ngrx/store';
import {
  AgenziaRefModel,
  AnagraficaCountryModel,
  AnagraficaFornitoreModel,
  ClienteTipologiaModel,
  DocumentoTipologiaModel,
  FornitoreTipologiaModel,
  GlobalFilterModel,
  ProfileModel,
  TipoPagamentoModel,
} from '../../models/core.model';
import * as SharedActions from './shared.actions';

export const SHARED_FEATURE_KEY = 'shared';

export interface SharedState {
  globalFilters?: GlobalFilterModel;

  profile?: ProfileModel;
  agenzieRefList: AgenziaRefModel[];
  tipologieFornitori: FornitoreTipologiaModel[];
  tipologieClienti: ClienteTipologiaModel[];
  tipologiaDocumenti: DocumentoTipologiaModel[];

  anagraficaFornitoriFinanziaria: AnagraficaFornitoreModel[];

  tipoPagamentoList: TipoPagamentoModel[];

  countries: AnagraficaCountryModel[];

  attiAcquistoMissingCount?: number;

  error?: string | null; // last known error (if any)
}

export const INITIAL_STATE: SharedState = {
  agenzieRefList: [],
  tipologieFornitori: [],
  tipologieClienti: [],
  tipologiaDocumenti: [],
  anagraficaFornitoriFinanziaria: [],
  tipoPagamentoList: [],
  countries: [],
};

export const sharedReducer = createReducer(
  INITIAL_STATE,
  on(SharedActions.loadProfileSuccess, (state, action) => {
    return {
      ...state,
      profile: action.profile,
    };
  }),
  on(SharedActions.loadProfileFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(SharedActions.loadAgenzieRef, (state) => ({
    ...state,
  })),
  on(SharedActions.loadAgenzieRefSuccess, (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const omitTypename = (key: any, value: any) =>
      key === '__typename' ? undefined : value;

    const newPayload = JSON.parse(JSON.stringify(action.data), omitTypename);

    return {
      ...state,
      agenzieRefList: newPayload,
    };
  }),
  on(SharedActions.loadAgenzieRefFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(SharedActions.loadSharedData, (state) => ({
    ...state,
  })),
  on(SharedActions.loadSharedDataSuccess, (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const omitTypename = (key: any, value: any) =>
      key === '__typename' ? undefined : value;

    const newFornitoreTipologia = JSON.parse(
      JSON.stringify(action.fornitoreTipologia),
      omitTypename,
    );

    const newClienteTipologia = JSON.parse(
      JSON.stringify(action.clienteTipologia),
      omitTypename,
    );

    const newDocumentiTipologia = JSON.parse(
      JSON.stringify(action.tipoDocumentiTipologia),
      omitTypename,
    );

    const newPayloadTipoPagamentoList = JSON.parse(
      JSON.stringify(action.tipoPagamentoList),
      omitTypename,
    );

    const newPayloadAnagraficaFornitoriFinanziaria = JSON.parse(
      JSON.stringify(action.anagraficaFornitoriFinanziaria),
      omitTypename,
    );

    const newPayloadCountries = JSON.parse(
      JSON.stringify(action.countries),
      omitTypename,
    );

    return {
      ...state,
      tipologieFornitori: newFornitoreTipologia,
      tipologieClienti: newClienteTipologia,
      tipologiaDocumenti: newDocumentiTipologia,
      anagraficaFornitoriFinanziaria: newPayloadAnagraficaFornitoriFinanziaria,
      tipoPagamentoList: newPayloadTipoPagamentoList,
      countries: newPayloadCountries,
    };
  }),
  on(SharedActions.loadSharedDataFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(SharedActions.setGlobalFilters, (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const omitTypename = (key: any, value: any) =>
      key === '__typename' ? undefined : value;

    const newPayload = JSON.parse(
      JSON.stringify(action.globalFilters),
      omitTypename,
    );

    return {
      ...state,
      globalFilters: newPayload,
    };
  }),
  on(SharedActions.loadAttiAcquistoMissingCountSuccess, (state, action) => {
    return {
      ...state,
      attiAcquistoMissingCount: action.count,
    };
  }),
);
