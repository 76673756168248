import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Location } from '@angular/common';

import { catchError, forkJoin, map, of, switchMap, take, tap } from 'rxjs';
import { ProfileService } from '../services/profile.service';

import * as RootActions from './root.actions';
import * as Sentry from '@sentry/angular';
@Injectable()
export class RootEffects {
  loadProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.loadProfile),
      switchMap(() =>
        forkJoin({
          user: this.service.getUser().pipe(take(1)),
          profile: this.service.getProfile().pipe(take(1)),
        }).pipe(
          map(({ user, profile }) => {
            Sentry.setUser({
              email: user?.email,
              username: user?.nickname,
            });

            return RootActions.loadProfileSuccess({
              user: {
                firstName: user?.name,
                lastName: user?.family_name,
                mail: user?.email,
                picture: user?.picture,
                profile: profile,
              },
            });
          }),
          catchError((error) =>
            of(
              RootActions.loadProfileFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.logout),
        tap(() => {
          this.service.logout();
        }),
      ),
    { dispatch: false },
  );

  goEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.go),
        tap((action) => {
          this.router.navigateByUrl(action.path);
        }),
      ),
    { dispatch: false },
  );

  backEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.back),
        tap(() => this.location.back()),
      ),
    { dispatch: false },
  );

  forwardEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.forward),
        tap(() => this.location.forward()),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly service: ProfileService,
    private router: Router,
    private location: Location,
  ) {}
}
