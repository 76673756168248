import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { Router, RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { UiModule } from '@tuacar-workspace/ui';
import { CoreModule, environment } from '@tuacar-workspace/core';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { GraphQLModule } from './graphql.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { it_IT, NZ_I18N } from 'ng-zorro-antd/i18n';
import * as Sentry from '@sentry/angular';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as fromRoot from './+state/root.reducer';
import { RootEffects } from './+state/root.effects';
import { IonicModule } from '@ionic/angular';
import { HomeComponent } from './home/home.component';
import { UserComponent } from './user/user.component';

registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [AppComponent, HomeComponent, UserComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    UiModule,
    IonicModule.forRoot({}),
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    GraphQLModule,
    BrowserAnimationsModule,
    // Import the module into the application, with configuration
    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      issuer: environment.auth0Domain,
      authorizationParams: {
        audience: 'https://manager.tuacar.it',
        redirect_uri: window.location.origin,
      },
      // Specify configuration for the interceptor
      httpInterceptor: {
        allowedList: [
          // {
          //   // Match any request that starts 'https://YOUR_DOMAIN/api/v2/' (note the asterisk)
          //   uri: 'api/graphql',
          //   // tokenOptions: {
          //   //   // The attached token should target this audience
          //   //   audience: 'https://YOUR_DOMAIN/api/v2/',
          //   //   // The attached token should have these scopes
          //   //   scope: 'read:current_user'
          //   // }
          // },
          // {
          //   uri: environment.graphqlUri,
          // },
          {
            uri: environment.backendBaseUri + '*',
          },
        ],
      },
    }),
    StoreModule.forRoot(fromRoot.reducers, {
      metaReducers: !environment.production ? [] : [],
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      connectInZone: true,
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),
    StoreModule.forFeature(fromRoot.ROOT_FEATURE_KEY, fromRoot.rootReducer),
    EffectsModule.forFeature([RootEffects]),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: NZ_I18N, useValue: it_IT },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
