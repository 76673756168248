import { Component, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { loadProfile, logout } from '../+state/root.actions';
import { UserModel } from '../models/profile.models';
import { Observable, Subscription } from 'rxjs';
import { getProfile } from '../+state/root.selectors';

@Component({
  selector: 'tuacar-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnDestroy {
  profile$: Observable<UserModel | undefined>;
  sub = new Subscription();
  profile: UserModel | undefined;

  constructor(private store: Store) {
    this.store.dispatch(loadProfile());

    this.profile$ = this.store.pipe(select(getProfile));

    this.sub.add(
      this.profile$.subscribe((profile) => {
        this.profile = profile;
      })
    );
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  logout() {
    this.store.dispatch(logout());
  }
}
