<ion-app>
  <ion-tabs>
    <ion-tab-bar slot="bottom">
      <ion-tab-button tab="home">
        <ion-icon name="home-outline"></ion-icon>
        Home
      </ion-tab-button>
      <ion-tab-button tab="load-car">
        <ion-icon name="camera-outline"></ion-icon>
        Carica foto auto
      </ion-tab-button>
      <ion-tab-button tab="user">
        <ion-icon name="person-outline"></ion-icon>
        Utente
      </ion-tab-button>
      <!-- <ion-tab-button tab="library">
      <ion-icon name="library"></ion-icon>
      Library
    </ion-tab-button>
    <ion-tab-button tab="search">
      <ion-icon name="search"></ion-icon>
      Search
    </ion-tab-button> -->
    </ion-tab-bar>
  </ion-tabs>
</ion-app>
